/* Estilos para el contenedor principal */
.containerHome {
  background: linear-gradient(to bottom, #242d58, #79c8ce);
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex; /* Para que el contenido ocupe toda la altura */
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  position: absolute;
}

/* Estilos para las tarjetas */
.card {
  background-color: #fff; /* Color de fondo de las tarjetas */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
  padding: 20px; /* Espaciado interno */
  margin-bottom: 20px; /* Margen inferior */
  margin-left: 50px;
  cursor: pointer; /* Cambia el cursor al pasar sobre la tarjeta */
  transition: box-shadow 0.3s ease; /* Transición suave de la sombra */

  /* Estilos cuando se pasa el cursor sobre la tarjeta */
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

/* Estilos para los títulos h5 dentro de las tarjetas */
.card h5 {
  font-family: 'Arial', sans-serif; /* Fuente del texto */
  background: linear-gradient(to right, #ff8a00, #e52e71); /* Gradiente de colores */
  -webkit-background-clip: text; /* Aplicar el relleno de color al texto */
  background-clip: text; /* Aplicar el relleno de color al texto */
  -webkit-text-fill-color: transparent; /* Hacer el texto transparente */
 
}

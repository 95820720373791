.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  
}

.formContainerSig {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px; /* Puedes ajustar el ancho según tus necesidades */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to bottom, #242d58, #79c8ce);
  margin-top: 50px;
}

.formTitleSig {
  margin-bottom: 20px;
  font-family: 'Raleway';
  font-size: 24px;
  color: #e3ecf0;
  
}

label {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.passwordInput {
  position: relative;
}

.passwordInput svg {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.forgotPasswordLink{
  color: #34728d;
}

.error {
  color: rgb(12, 7, 7);
  margin-top: 4px;
}

.register {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register span {
  margin-bottom: 8px;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.red {
  background-color: #34728d;
  color: #fff;
}

.red:hover {
  background-color: #335285;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Reem Kufi", sans-serif !important;
}

body {
    overflow-x: hidden; /* Evita la barra de desplazamiento horizontal */
    min-height: 100vh;
    margin: 0;
  
    box-sizing: border-box;
   
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; 
}

.content {
    margin-top: 90px; 
     

}
.no-padding-top {
    padding-top: 0 !important;
  }
  
@media (max-width: 768px) {
    .content {
        padding: 10px; /* Reduce el espacio interno en pantallas pequeñas */
    }
}

@media (max-width: 480px) {
    .content {
        padding: 5px; /* Aún más pequeño para pantallas muy pequeñas */
        
    }
}




.client-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-top: 40px;
  
}


.table-container {
  width: 100%;
  overflow-x: auto; 
}
.th-table-title{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #206482;
}
.user-table {
  width: 100%; 
  table-layout: fixed; 
}

.user-table h2 {
  margin-bottom: 10px;
}

.user-table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.user-table th,
.user-table td {
  width: 200%;
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; /* Muestra puntos suspensivos (...) cuando el contenido de la celda es demasiado largo */
}

.user-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.user-table tr:hover {
  background-color: #ddd;
}

.user-table h2 {
  margin-bottom: 10px;
  text-align: center;
  font-size: 24px;
  color: #333;
}

.button-group-container {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 10%;

}
.link {
  text-decoration: none;
}
/* Estilos para el efecto de botón en hover */
.client-item:hover {
  background-color: #22c4cf;
  color: white;
  transform: scale(1.03);
}

.client-item:hover td {
  color: white;
}

/* Estilos para el efecto de botón en click */
.client-item:active {
  background-color: #22c4cf;
}

.client-item:active td {
  color: white;
  font-family: 'Times New Roman', Times, serif;
}

/* Estilo para la clase "asc" */
.asc {
  background-color: #f0ed46; /* Cambia el color de fondo para ascendente */
  color: #22c4cf;
}

.asc:hover {
  background-color: #f0ed46;
}

/* Estilo para la clase "desc" */
.desc {
  background-color: #d25037; /* Cambia el color de fondo para descendente */
}

.desc:hover {
  background-color: #d25037;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra los elementos horizontalmente */
  gap: 5px; /* Espacio entre los botones de paginación */
  margin-top: 15px;
  margin-bottom: 10px;
}

.pagination-container button {
 
  margin: 0 2px; /* Espacio entre botones */
  
}

.paginate {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 10px 10px;
  height: 1px;
  
  border: none;
  background-color: #206482;
  color: white;
  cursor: pointer;
}
.paginate:hover {
  background-color: #9481b2;
}

.paginate.active {
  background-color: #100645; /* Cambia el color cuando esté activo */
}

.page-number {
  margin-left: 0.5%; /* Espacio entre el ícono y el número */
}

.asc-icon {
  font-size: 20px;
  padding-left: 2.5%;
  margin-top: 0.2%;
}

.desc-icon {
  font-size: 20px;
  padding-left: 2.5%;
  margin-top: 0.2%;
}
.client-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px; /* Ajusta el espacio desde arriba */
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Espacio entre el input de búsqueda y la tabla */
}

.search-input {
  margin-right: 10px; /* Espacio entre el input de búsqueda y los iconos */
  width: 300px; /* Ajusta el ancho del input de búsqueda según tus necesidades */
}

.table-container {
  width: 80%; /* Ajusta el ancho de la tabla según tus necesidades */
}
.th-table-title{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #106fbd;
}

/* Estilos para botones en pantallas pequeñas */
@media (max-width: 600px) {
  .user-table table, .user-table th, .user-table td {
      display: block;
  }
  .user-table th, .user-table td {
      text-align: left !important;
  }
  .user-table th {
      font-weight: bold;
  }
  .small-buttons-container {
    position: fixed; 
    top: 5%; 
    right: 10px;
    z-index: 999; 
    display: flex; 
    flex-direction: row; 
    align-items: flex-end; 
    margin: auto;
    
  }
  .small-button {
    margin-top: 5px; 
    padding: 5px 10px; 
    font-size: 10px; 
    background-color: #206482; 
    color: white; 
  }
  .search-input {
    top: 5%; 
   
  }
}




.container {
  max-width: 800px;
  margin: 10px auto 0;
  
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.2px;
  display: flex;
  flex-direction: column; /* Alinear elementos en columna */
  align-items: center; /* Centrar elementos horizontalmente */
}

.header {
  
  text-align: center;
 
  font-weight: bold; /* Texto en negrita */
  font-size: 24px; /* Tamaño de letra más grande */

}

.table-container {
  margin-top:20px ;
  margin-bottom: 20px;
}

/* Estilos para resaltar los títulos de la tabla */
.table-header-cell {
  color: black;
  background-color: #f0f0f0; /* Fondo gris claro */
}
.summary {
  text-align: center;
}

@media (max-width: 1024px) {
  .container {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .container {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .container {
    font-size: 12px;
  }
}


  .success-message {
    background-color: #4CAF50; /* Color de fondo verde */
    color: white; /* Texto en color blanco */
    padding: 10px; /* Espaciado interno */
    margin-bottom: 10px; /* Espacio en la parte inferior */
    border-radius: 4px; /* Bordes redondeados */
  }
  
  /* Estilos para el mensaje de error */
  .error-message {
    background-color: white; /* Color de fondo rojo */
    color: red; /* Texto en color blanco */
    padding: 5px; /* Espaciado interno */
    margin-bottom: 10px; /* Espacio en la parte inferior */
    border-radius: 4px; /* Bordes redondeados */
  }
  